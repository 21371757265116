import { useContext, useEffect, useState } from 'react'
import '../../styles/home_fifth.css'
import api from "../../utils/api";
import Image from "../../components/Image";
import locals from '../../utils/locals';
import { LanguageContext } from "../../context/language";
function FifthPage() {
  const [list, setList] = useState([])
  const { local } = useContext(LanguageContext)
  useEffect(() => {
    getData()
  }, [])
  const getData = () => {
    fetch(api + '/activitys/activity/')
      .then((e) => e.json())
      .then((res) => {
        setList(res)
      })
  }
  // useEffect(() => {
  //   console.log(list);
  // }, [list])
  return (
    <div className="fifth_page" id='fifth-page'>
      <div className='fifth_content'>
        <div
          className='fifth_flex'>
          <div className='item' style={{
            width: window.innerWidth < 812 ?
              ((36 * window.innerHeight / window.innerWidth) + '%') : '90%',
            maxWidth: '76%'
          }}>
            <div className='fif_video_box'>
              <img alt='' className='video_img' src='/images/fif_m11.png'></img>
              <div className='video_box' style={{
                left: '33.8%',
                top: '26.1%',
                width: '65.5%',
                height: '39.4%'
              }}>
                {list && list.length && <Image
                  isHome
                  // preview={list && list.length && list[0].img}
                  motion_video={list[2].motion_video}
                  src={[list[2].video]}></Image>}
              </div>
            </div>
            {/* <img alt='' className='fif_img' style={{
              position: 'relative',
              left: 'calc(50% - 4.4vw)',
              width: '8vw'
            }} src='/images/fif1.png'></img> */}
          </div>
          <div className='item' style={{
            width: window.innerWidth < 812 ?
              ((40.7 * window.innerHeight / window.innerWidth) + '%') : '90%',
            maxWidth: '90%'
          }}>
            {/* <img alt='' className='fif_img' style={{
              marginLeft: 'calc(50% - 10vw)',
              marginBottom: '2vw'
            }} src='/images/fif2.png'></img> */}
            <div className='fif_video_box' style={{
              marginLeft: '5%'
            }}>
              <img alt='' className='video_img' src='/images/fif_m22.png'></img>
              <div className='video_box' style={{
                left: '15.6%',
                top: '38.7%',
                width: '53.6%',
                height: '46.5%'
              }}>
                {list && list.length && <Image
                  isHome
                  // preview={list && list.length && list[1].img}
                  motion_video={list[1].motion_video}
                  src={[list[1].video]}></Image>}
              </div>
            </div>
          </div>
          <div className='item' style={{
            width: window.innerWidth < 812 ?
              ((43.5 * window.innerHeight / window.innerWidth) + '%') : '90%',
            maxWidth: '90%'
          }}>
            <div className='fif_video_box' style={{
              marginTop: window.innerWidth < 812 ? 0 : '17%'
            }}>
              <img alt='' className='video_img' src='/images/fif_m33.png'></img>
              <div className='video_box' style={{
                left: '25.3%',
                top: '25.88%',
                width: '50.6%',
                height: '39%'
              }}>
                {list && list.length && <Image
                  isHome
                  // preview={list && list.length && list[2].video}
                  motion_video={list[0].motion_video}
                  src={[list[0].video]}></Image>}
              </div>
            </div>
            {/* <img alt='' className='fif_img' style={{
              position: 'relative',
              width: '8vw',
              left: '-10.5vw',
              top:'-1vw'
            }} src='/images/fif3.png'></img> */}
          </div>
        </div>
        <div className='join'>
          <div className='join_box'>
            <div className='join_link' onClick={() => {
              window.open('https://www.zhipin.com/gongsi/2951ea93f0e6c8611HN53dW9.html')
            }}>{locals[local].join}</div>
            <img alt='' className='join_arrow join_arrow1' src='/images/fif_arrow1.png'></img>
            <img alt='' className='join_arrow join_arrow2' src='/images/fif_arrow2.png'></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FifthPage;
